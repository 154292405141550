import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "./navbar";
import jwtDecode from "jwt-decode";
import Loader from "./loader";
import Cookies from "js-cookie";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
  Table,
} from "react-bootstrap";
import "../components/main.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataFrameSummary from "../components/summaryformat";

function UploadCsvFile() {
  /////////////////////////////// States /////////////////////////////
  const [show, setShow] = useState("d-none");
  const [file, setFile] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseReceived, setresponseReceived] = useState(null);
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);
  const [links, setLinks] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [summary, setSummary] = useState("");
  const [filetitle, setFiletitle] = useState("");

  /////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////// setting file to upload /////////////
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  ////////////////////////////////////////////////////////////////
  const handleSubmitform = (e) => {
    e.preventDefault();
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    ///////////////////////////////////// Request sent /////////////////////////////////////////
    const formData = new FormData();
    formData.append("file", file);
    const token = Cookies.get("token");
    const { username } = jwtDecode(token);
    formData.append("username", username);

    const isFileSelected = file !== null;
    const isFileSizeValid = file && file.size <= 10485760; // 1MB in bytes

    const isFileValidType = file && file.type === "text/csv";
    if (isFileSelected && isFileValidType && isFileSizeValid) {
      setIsLoading(true);
      axios
        .post("https://data-analysis-app.vantiqa.com/api/upload", formData)
        .then((res) => {
          setIsFileUploaded(true);
          const keysArray = [];
          const valuesArray = [];
          sessionStorage.setItem("fileTitle", file.name);
          setFiletitle(file.name);
          for (const [key, value] of Object.entries(res.data)) {
            if (
              !key.startsWith("Unnamed") &&
              !key.startsWith("Sure") &&
              !key.startsWith("object")
            ) {
              keysArray.push(key);
              valuesArray.push(value);
            }
          }
          if (valuesArray.length > 0) {
            setTableHeaders(Object.keys(valuesArray[0]));
          }

          setKeys(keysArray);
          setValues(valuesArray);
          sessionStorage.setItem("keys", JSON.stringify(keysArray));
          sessionStorage.setItem("values", JSON.stringify(valuesArray));

          toast.success("File uploaded successfully");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setIsLoading(false);
        });
      axios
        .post("https://data-analysis-app.vantiqa.com/api/summary", formData)
        .then((res) => {
          const response = res.data.data;

          setSummary(response);
          sessionStorage.setItem("summary", response);

          setresponseReceived(true);

          setIsLoading(false);
          setShow("d-block");
        })
        .catch((err) => console.log(err));
    } else {
      setIsLoading(false);

      if (!isFileSelected) {
        toast.error("Please enter a Csv file");
      } else if (!isFileValidType) {
        toast.error("Invalid file type. Please select a csv file");
      } else if (!isFileSizeValid) {
        toast.error("File size exceeds limit of 10MB");
      }
    }
  };

  useEffect(() => {
    // Retrieve values from sessionStorage
    const savedKeys = sessionStorage.getItem("keys");
    const savedValues = sessionStorage.getItem("values");
    const fileTitle = sessionStorage.getItem("fileTitle");
    const summary = sessionStorage.getItem("summary");

    // If values are found in sessionStorage, parse them and update state
    if (savedKeys && savedValues && fileTitle && summary) {
      setKeys(JSON.parse(savedKeys));
      setValues(JSON.parse(savedValues));
      setTableHeaders(Object.keys(JSON.parse(savedValues)[0]));
      setFiletitle(fileTitle);
      setSummary(summary);

      setShow("d-block");
    }
  }, []);

  useEffect(() => {
    const newLinks = ["CsvChat", "Plot", "Statistics"];
    setLinks(newLinks);
  }, []);
  return (
    <div className="app-wrapper">
      <NavBar />
      <ul className="nav nav-tabs">
        {links.map((link, index) => (
          <li
            key={index}
            className="nav-item animate__bounceInRight animate__animated"
          >
            <Link
              className={`nav-link ${"Statistics" === link ? "active" : ""}`}
              to={`/${link}`}
            >
              {link}
            </Link>
          </li>
        ))}
      </ul>

      <Container>
        <Row className="justify-content-md-center">
          <h4 className="text-center text-primary mt-4 mb-3">
            File Statistics
          </h4>

          <Col xs={12} md={6}>
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmitform}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload File</Form.Label>
                    <Form.Control
                      disabled={isFileUploaded}
                      type="file"
                      onChange={handleChange}
                      accept=".csv"
                    />
                    <Form.Text className="text-muted">
                      File size limit: 10MB
                    </Form.Text>
                  </Form.Group>
                  {responseReceived ? (
                    <></>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="submitbutton"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          fill="currentColor"
                          d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                        ></path>
                      </svg>
                      <span className="fw-bold ">Upload</span>
                    </button>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-md-center mt-5">
          {isLoading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <div className={`container ${show}`}>
                <h4 className="text-center text-primary mt-4 mb-3">
                  {filetitle} Statistics
                </h4>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>Key</th>
                      {tableHeaders.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {keys.map((key, index) => (
                      <tr key={index}>
                        <td>{key}</td>
                        {tableHeaders.map((header, idx) => (
                          <td key={idx}>{values[index][header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <DataFrameSummary summary={summary} />
              </div>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default UploadCsvFile;
