import Navbar from "react-bootstrap/Navbar";
import logo from "../images/Vantiqa logos-12.png";
import { Link } from "react-router-dom";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

function NavBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get("token"));
  const history = useHistory();

  const logout = () => {
    Cookies.remove("token");
    setIsLoggedIn(false);
    history.push("/");
  };

  return (
    <Navbar
      style={{ backgroundColor: "rgba(174, 224, 219, 0.89)" }}
      className="px-3 py-2"
    >
      <Navbar.Brand className="d-flex align-items-center justify-content-between w-100 flex-column flex-md-row">
        <div className="d-flex align-items-center mb-3 mb-md-0">
          <img
            src={logo}
            alt="Vantiqa Logo"
            height="50"
            className="d-inline-block align-top mr-2 fs-bold"
          />
          <Link
            to="/dataanaylsis"
            style={{ textDecoration: "none", color: "inherit" }}
            className="d-inline-block animate__jello animate__animated"
          >
            Vantiqa data app
          </Link>
        </div>
        <div className="d-flex align-items-center flex-column flex-md-row">
          {isLoggedIn && (
            <Link
              to="/"
              className="nav-link active fs-5"
              aria-current="page"
              onClick={logout}
              style={{ color: "inherit" }}
            >
              Logout
            </Link>
          )}
        </div>
      </Navbar.Brand>
    </Navbar>
  );
}
export default NavBar;
