import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import LoginPage from './pages/login';
import PrivateRoute from './components/PrivateRoute';
import UploadCsvFile from './pages/csvUpload';
import Plot from './pages/plot';
import Chat from './pages/Chat';
import AnalysisRoute from './components/AnaylsisRoute';

function App() {
  return (
    <div className="App">
      <ToastContainer />

      <BrowserRouter >
        <Switch>
          <PrivateRoute exact path="/Statistics" component={UploadCsvFile} />
          <AnalysisRoute exact path="/Plot" component={Plot} />
          <AnalysisRoute exact path="/CsvChat" component={Chat} />
          <Route exact path={"/"} component={LoginPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
