import React from 'react';
import '../components/main.css';

const Loader = () => (
    <section className="dots-container">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </section>
  
);

export default Loader;
