import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function DataFrameSummary({ summary }) {
  const [prefix, setPrefix] = useState("");
  useEffect(() => {
    // Check if the summary contains the word "Sure" with the prefix
    if (summary.includes("Sure")) {
      setPrefix("Sure! The columns in the dataframe are as follows:");
    } else {
      setPrefix("The columns in the dataframe are as follows:");
    }
  }, [summary]); // Only run this effect when 'summary' changes

  const datatypePrefix =
    "The datatype of each column in the dataframe is as follows:";

  const hasDataTypes = summary.includes(datatypePrefix);

  const columnDescriptions = summary
    .split(datatypePrefix)[0]
    .replace(prefix, "")
    .trim()
    .split(".")
    .filter(Boolean)
    .map((desc) => desc.replace(/^\d+\.\s*/, "").trim())
    .filter((desc) => !/^\d+$/.test(desc))
    .filter((desc) => !desc.includes("object"));

  const columnDataTypes = hasDataTypes
    ? summary.split(datatypePrefix)[1]?.trim().split("-").filter(Boolean)
    : [];

  const chunkData = (data, chunkSize) => {
    let chunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <div className="container mt-4">
      <h3>Columns in the DataFrame</h3>
      {chunkData(columnDescriptions, 3).map((chunk, idx) => (
        <div key={idx} className="row mb-4">
          {chunk.map((desc, j) => {
            const [columnName, columnDescription] = desc
              .split(":")
              .map((item) => item.trim());
            return (
              <div key={j} className="col-md-4">
                <div className="card">
                  <div className="card-header">{columnName}</div>
                  <div className="card-body">{columnDescription}</div>
                </div>
              </div>
            );
          })}
        </div>
      ))}

      {hasDataTypes && (
        <>
          <h3 className="mt-4">Data Types of the Columns</h3>
          {chunkData(columnDataTypes, 3).map((chunk, idx) => (
            <div key={idx} className="row mb-4">
              {chunk.map((type, j) => {
                const [columnName, columnType] = type
                  .split(":")
                  .map((item) => item.trim());
                return (
                  <div key={j} className="col-md-4">
                    <div className="card">
                      <div className="card-header">{columnName}</div>
                      <div className="card-body">{columnType}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default DataFrameSummary;
