import React from "react";
import { Redirect, Route } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const AnalysisRoute = ({ component: Component, ...rest }) => {
  const user_token = Cookies.get("token");

  const file_token = sessionStorage.getItem("fileTitle");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user_token) {
          return <Redirect to="/" />;
        } else if (user_token && !file_token) {
          toast.error("You should upload a file first.");
          return <Redirect to="/Statistics" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AnalysisRoute;
