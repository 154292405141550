import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NavBar from "./navbar";
import "../components/plot.css";
import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import ImageDisplay from "../components/image";
import Loader from "./loader";
import userpicture from "../images/user__1_-removebg-preview.png";
import botpicture from "../images/robot-removebg-preview.png";
const Plot = () => {
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };
  const [chatMessages, setChatMessages] = useState(() => {
    const savedMessages = sessionStorage.getItem("PlotMessages");
    if (savedMessages && JSON.parse(savedMessages).length > 0) {
      return JSON.parse(savedMessages);
    } else {
      return [
        { sender: "bot", content: "Hello, How are you ? what would you do ?" },
      ];
    }
  });
  const [userInput, setUserInput] = useState("");
  const [links, setLinks] = useState([]);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };
  const handleSendMessage = () => {
    if (userInput.trim() !== "") {
      const newMessage = {
        sender: "user",
        content: userInput,
      };

      setChatMessages((prevMessages) => [
        ...prevMessages,
        newMessage,
        { sender: "bot", content: "<Loader />" },
      ]);

      const { username } = jwtDecode(Cookies.get("token"));
      const filename = sessionStorage.getItem("fileTitle");

      const data = new FormData();
      data.append("username", username);
      data.append("filename", filename);
      data.append("message", userInput);

      axios
        .post("https://data-analysis-app.vantiqa.com/api/plot", data)
        .then((res) => {
          if (res.data == null) {
            setChatMessages((prevMessages) => {
              const newMessages = [...prevMessages];
              newMessages[newMessages.length - 1].content =
                "There is no plot for your request.";
              return newMessages;
            });
          } else {
            const base64Image = `data:image/png;base64,${res.data}`;
            setChatMessages((prevMessages) => {
              const newMessages = [...prevMessages];
              newMessages[newMessages.length - 1].content = base64Image;
              return newMessages;
            });
          }
        })
        .catch((err) => {
          setChatMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[newMessages.length - 1].content =
              "There is no plot for your request.";
            return newMessages;
          });
        });

      setUserInput("");
    }
  };

  const handleSubmitform = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    sessionStorage.setItem("PlotMessages", JSON.stringify(chatMessages));
  }, [chatMessages]);

  useEffect(() => {
    const newLinks = ["CsvChat", "Plot", "Statistics"];
    setLinks(newLinks);
  }, []);
  return (
    <div className="app-wrapper">
      <NavBar />

      <ul className="nav nav-tabs">
        {links.map((link, index) => (
          <li
            key={index}
            className="nav-item animate__bounceInRight animate__animated"
          >
            <Link
              className={`nav-link ${"Plot" === link ? "active" : ""}`}
              to={`/${link}`}
            >
              {link}
            </Link>
          </li>
        ))}
      </ul>
      <Container className="mt-5">
        <Row>
          <h4 className="text-center text-primary mt-4 mb-3">
            File Visualization
          </h4>
          <Col sm={12} md={12}>
            <div className="chat-box">
              {chatMessages.map((message, index) => (
                <div
                  className={`message ${
                    message.sender === "user" ? "user-message" : "bot-message"
                  }`}
                  key={index}
                >
                  <div>
                    <img
                      src={message.sender === "user" ? userpicture : botpicture}
                      alt={message.sender}
                      className="img-fluid message-icon"
                    />

                    {message.sender === "bot" &&
                    message.content === "<Loader />" ? (
                      <Loader />
                    ) : message.sender === "bot" &&
                      isValidUrl(message.content) ? (
                      <ImageDisplay imageUrl={message.content} />
                    ) : (
                      message.content
                    )}
                  </div>
                </div>
              ))}
            </div>

            <Form onSubmit={handleSubmitform}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter your message"
                  value={userInput}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <button
                className="btn btn-primary mt-3"
                style={{ display: "block", width: "25%", marginLeft: "0" }} // Inline style added to reset any styles and align to the start
                onClick={handleSendMessage}
              >
                Send
              </button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Plot;
