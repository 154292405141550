import React, { useCallback, useEffect, useRef, useState } from "react";
import "../components/login.css";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const initialStateInfo = {
  username: "",
  password: "",
};
const initialErrorState = {
  UserErr: "",
  PasswordErr: "",
  empty: "",
};

function LoginPage() {
  const [info, setInfo] = useState(initialStateInfo);
  const [error, setErr] = useState(initialErrorState);

  const history = useHistory();

  const userRef = useRef(null);
  const passwordRef = useRef(null);
  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      history.push("/Statistics");
    }
  }, [history]);
  //pushing to edit the database url
  const ChangeInfo = useCallback((e) => {
    setInfo((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  }, []);
  const ValidateUser = useCallback(
    async (e) => {
      e.preventDefault();
      const username = userRef.current.value;
      const password = passwordRef.current.value;
      if (username.length === 0 || password.length === 0) {
        toast.error("Please fill all fields");
      } else {
        try {
          const { data } = await axios.post(
            "https://data-analysis-app.vantiqa.com/api/login",
            info,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (data.token) {
            Cookies.set("token", data.token);

            const { username } = jwtDecode(data.token);

            toast.success(
              `Welcome Mr. ${
                username.charAt(0).toUpperCase() +
                username.slice(1).toLowerCase()
              }`
            );
            history.push("/Statistics");
          }
        } catch ({ response: { data } }) {
          setErr((prevError) => ({ ...prevError, PasswordErr: data.error }));
          toast.error(data.error);
        }
      }
    },
    [info, history]
  );

  const submitUserData = (e) => {
    e.preventDefault();
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={(e) => submitUserData(e)}>
          <div className="user-box">
            <input
              type="text"
              placeholder="Username"
              ref={userRef}
              name="username"
              onChange={(e) => ChangeInfo(e)}
              autoComplete="username"
              required
            />
            <label>Username</label>
          </div>
          <div className="user-box">
            <input
              type="password"
              placeholder="Password"
              ref={passwordRef}
              name="password"
              onChange={(e) => ChangeInfo(e)}
              autoComplete="current-password"
              required
            />
            <label>Password</label>
          </div>
          <Link to="" onClick={(e) => ValidateUser(e)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Login
          </Link>{" "}
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
