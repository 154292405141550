import React from "react";

function ImageDisplay({ imageUrl }) {
  return (
    <div>
      <img className="img-fluid" src={imageUrl} alt="Chart" />
    </div>
  );
}

export default ImageDisplay;
